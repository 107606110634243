import { INavData } from '@coreui/angular';
import { User as RUser } from './interfaces/user';
import { environment } from "../environments";

interface User extends RUser {
  slots: number;
}

export const navItems: (INavData & { role?: string[], filter?: (user: User) => boolean, i18nkey: string, isFree: boolean })[] = [
  {
    name: 'Tutorials',
    i18nkey: 'tutorials',
    url: '/home-page',
    icon: 'icon-home',
    isFree: true
  },
  {
    name: 'Tournaments',
    i18nkey: 'tournaments',
    url: '/tournaments',
    icon: 'icon-globe',
    filter: (user) => !user.team || user.role.includes('MASTER'),
    isFree: true,
  },
  {
    name: 'Manage Schedules',
    i18nkey: 'manage_schedules',
    url: '/scheduled-tournaments',
    icon: 'icon-calendar',
    isFree: false,
  },
  {
    name: 'Rebalance Schedule',
    i18nkey: 'rebalance_schedule',
    url: '/rebalance',
    icon: 'fa fa-balance-scale',
    role: ['MASTER'],
    isFree: false,
    badge: { text: "Beta", variant: "top", class: "bg-primary beta-badge" }
  },
  {
    name: 'Approve Tournaments',
    i18nkey: 'approve_tournaments',
    url: '/approve-tournaments',
    icon: 'fa fa-bullhorn',
    role:['MASTER'],
    filter: (user) => (!user.team || user.role.includes('MASTER')) && (user.user_count > 1 || user.slots > 1),
    isFree: true,
  },
  {
    name: 'Tournament Requests',
    i18nkey: 'tournament_requests',
    url: '/requested-tournaments',
    icon: 'fa fa-envelope-open-o',
    isFree: false,
  },
  {
    name: 'Manage Team',
    i18nkey: 'manage_team',
    url: '/my-team',
    icon: 'icon-people',
    role: ['MASTER'],
    filter: (user) => user.user_count > 1 || user.slots > 1,
    isFree: false,
  },
  {
    name: 'Add Tournaments',
    i18nkey: 'add_tournaments',
    url: '/add-tournaments-tier',
    icon: 'icon-note',
    role: ['MASTER'],
    isFree: false,
    badge: { text: "Beta", variant: "top", class: "bg-primary beta-badge" }
  },
  {
    name: 'Manage Logs',
    i18nkey: 'manage_logs',
    url: '/manage-logs',
    icon: 'fa fa-file-text-o',
    role: ['MASTER'],
    isFree: false,
  },
  /* {
    name: 'Tournament Qualifiers',
    i18nkey: 'tournament_level_config',
    url: '/level-config',
    icon: 'fa fa-line-chart',
    role: ['MASTER'],
    isFree: false,
  }, */
  {
    name: 'User Profile',
    i18nkey: 'user_profile',
    url: '/user-profile',
    icon: 'icon-people',
    isFree: false,
  },
  {
    title: true,
    name: 'Admin',
    i18nkey: 'admin',
    role: ['TA', 'SA'],
    isFree: false,
  },
  {
    name: 'Add Tournaments',
    i18nkey: 'add_tournaments',
    url: '/add-tournaments',
    icon: 'icon-note',
    role: ['TA', 'SA'],
    isFree: false
  },
  {
    name: 'Manage Sites & Tags',
    i18nkey: 'manage_sites_tags',
    url: '/manage-sites',
    icon: 'icon-tag',
    role: ['TA', 'SA'],
    isFree: false
  },
  {
    name: 'Manage Users',
    i18nkey: 'manage_users',
    url: '/manage-users',
    icon: 'icon-people',
    role: ['SA'],
    isFree: false
  },
  {
    name: 'Manage Teams',
    i18nkey: 'manage_teams',
    url: '/manage-teams',
    icon: 'icon-people',
    role: ['SA'],
    isFree: false
  },
  {
    name: 'Manage Structures',
    i18nkey: 'manage_structures',
    url: '/manage-structures',
    icon: 'icon-layers',
    role: ['TA', 'SA'],
    isFree: false
  },
  {
    name: 'Manage Banner',
    i18nkey: 'manage_banner',
    url: '/manage-ads',
    icon: 'icon-frame',
    role: ['TA', 'SA'],
    isFree: false
  },
  {
    title: true,
    name: 'User',
    i18nkey: 'user',
    isFree: true
  },
  {
    name: 'Manage Bankroll',
    i18nkey: 'manage_bankroll',
    url: '/bankroll',
    icon: 'icon-chart',
    isFree: true,
    badge: { text: "Beta", variant: "top", class: "bg-primary beta-badge" }
  },
  {
    name: 'Playing Poker Report',
    i18nkey: 'playing_poker_report',
    url: '/day',
    icon: 'icon-magnifier',
    isFree: false
  },
  {
    name: 'All Time Reports',
    i18nkey: 'all_time_reports',
    url: '/stats',
    icon: 'icon-chart',
    isFree: false
  },
  {
    name: 'Notifications',
    i18nkey: 'notifications',
    url: '/notifications',
    icon: 'icon-bell',
    isFree: false
  },
  {
    title: true,
    name: 'Downloads',
    i18nkey: 'downloads',
    isFree: false
  },
  {
    name: 'Download Logs',
    i18nkey: 'download_logs',
    url: '#',
    href: environment.API_URL + 'v2/log/download',
    icon: 'icon-book-open',
    role: ['SA'],
    isFree: false
  },
  {
    name: 'Download Notification App',
    i18nkey: 'download_notification_app',
    url: '#',
    href: environment.API_URL + 'v2/misc/download',
    icon: 'icon-bell',
    isFree: false
  },
]
