<ngx-spinner size="medium" type = "ball-clip-rotate-multiple" color="#838cff"></ngx-spinner>
<ng-container>
<app-header [navbarBrandRouterLink]="['/dashboard']" [asideMenuToggler]="false" [fixed]="true" [navbarBrandFull]="{src: 'assets/img/brand/poker_logo.png', width: 107, height: 25, alt: 'lobbyze Logo'}" (click)="WindowResize($event)" [navbarBrandMinimized]="{src: 'assets/img/brand/logo2.png', width: 30, height: 30, alt: 'lobbyze Logo'}" [sidebarToggler]="'lg'" [mobileAsideMenuToggler]="false">
  <ul class="nav navbar-nav d-md-down-none" *ngIf="!isShopPage()">
    <!-- <li class="nav-item px-3">
      <a class="nav-link" [routerLink]="['/dashboard']">Dashboard</a>
    </li> -->
    <li class="nav-item px-3">
      <a class="nav-link" [routerLink]="['/tournaments']">{{ 'tournaments' | translate }}</a>
    </li>
    <li *ngIf="!isFreeLocked" class="nav-item px-3">
      <a class="nav-link" [routerLink]="['/scheduled-tournaments']">{{ 'schedules' | translate }}</a>
    </li>
  </ul>
  
  <div class="row m-0 ml-auto align-items-center justify-content-center">
    <div class="col-auto p-0 d-none d-lg-block" *ngIf="!isShopPage()">
      <div class="row m-0 align-items-center">
        <div class="col-auto p-0 px-1">
          <div class="nav-link" style="cursor: pointer;" (click)="goToClassicView()">
            <label class="switch">
              <div class="switchCheck" [ngClass]="{'checked': getClassicModeUrl().checked}">
                <span class="slider round"></span>
              </div>
            </label>
            <span>{{ 'switch_to_classic_view' | translate }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-auto p-0" *ngIf="!isShopPage()">
      <div class="row m-0 align-items-center">
        <div class="col-auto p-0 px-1">
          <div>
            <div class="dropdown" dropdown placement="bottom">
              <div class="tour-button" dropdownToggle>
                <span>{{ 'need_help' | translate }}</span><i class="fa fa-question-circle ml-2"></i>
              </div>
              <div class="dropdown-menu dropdown-menu-right dropdown-menu-guide" *dropdownMenu>
                <ng-container>
                  <a class="dropdown-item" style="cursor: pointer" (click)="executeNonMasterGuide()"><i class="fa fa-book"></i>{{ 'start_guide' | translate }}</a>
                  <a class="dropdown-item" style="cursor: pointer" (click)="executeRebalanceGuide()"><i class="fa fa-book"></i>{{ 'rebalance_guide' | translate }}</a>
                  <a class="dropdown-item" href="{{ getCurrentLang() == 'pt-BR' ? 'https://www.youtube.com/playlist?list=PLcVKNQHI3uo4Kbg0YUNkbNGzuN5LKSqqT' : 'https://www.youtube.com/playlist?list=PLcVKNQHI3uo5g3-hEWMFkAmRmSqnnKHQG' }}" target="_blank"><i class="fa fa-youtube-play mr-2"></i>{{ 'videos' | translate }}</a>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-auto p-0">
      <div class="row m-0 align-items-center">
        <div class="col-auto d-none d-md-block p-0 px-1">
          <div class="dropdown tier-dropdown mr-2" dropdown placement="bottom">
            <div class="tier-name" dropdownToggle>
              <span class="pl-1">{{ team }}</span>
              <i class="fa fa-angle-down" tooltip="Open tier list" triggers="hover" placement="bottom"></i>
            </div>
            <div class="dropdown-menu dropdown-menu-right tier-list" *dropdownMenu>
              <div class="row m-0" style="min-width: 350px;">
                <div class="col-6 p-0">
                  <ng-container *ngIf="allowNewTier$ | withLoading | async as allowNewTier">
                    <a
                      *ngIf="allowNewTier.value; else notAllow"
                      class="dropdown-item clickable"
                      (click)="addNewTierModal.show()"
                    >
                      Add new tier <i class="ml-1 fa fa-plus"></i>
                    </a>
                    <ng-template #notAllow>
                      <a class="dropdown-item"
                        tooltip="{{ 'tiers_must_have_4_users' | translate }}"
                        triggers="hover"
                        placement="bottom"
                      >
                        Add new tier<i class="ml-1 fa fa-ban"></i>
                      </a>
                    </ng-template>
                  </ng-container>
                </div>
                <div class="col-6 p-0">
                  <div class="input-group mt-1">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="fa fa-search"></i></span>
                    </div>
                    <input class="form-control" [(ngModel)]="tierSearch" (click)="$event.stopPropagation()" type="text">
                  </div>
                </div>
              </div>
              <ng-container *ngIf="tierList$ | withLoading | async as wait">
                <ng-container *ngIf="wait.value as tierList">
                  <ng-container *ngFor="let tier of tierList">
                    <a class="dropdown-item clickable" [class]="tier.member_count != null ? 'owner' : ''" *ngIf="searchCheck(tier.name)" (click)="selectTier(tier)"><i class="fa fa-book"></i>{{ tier.name }}{{ tier.member_count != null ? ' - ' + tier.member_count + ' users' : '' }}</a>
                  </ng-container>
                </ng-container>
                <span class="dropdown-item disabled" *ngIf="wait.loading"><i class="fa fa-arrow-circle-o-down"></i>{{ 'loading' | translate }}...</span>
                <span class="dropdown-item disabled" *ngIf="wait.error"><i class="fa fa-exclamation"></i>Error</span>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="col-auto p-0 px-2">
          <div class="language-picker-area">
            <div class="dropdown" dropdown placement="bottom">
              <div class="language-picker" dropdownToggle>
                <span [class]="getCurrentLang() == 'en-US' ? 'flag-icon-us' : getCurrentLang() == 'pt-BR' ? 'flag-icon-br' : getCurrentLang() == 'es' ? 'flag-icon-es' : 'flag-icon-ru'" class="flag-icon"></span>
              </div>
              <div class="dropdown-menu menu-flags dropdown-menu-right" *dropdownMenu>
                <ng-container>
                  <a class="item-flag clickable" (click)="selectLanguage('en-US')"><i class="flag-icon flag-icon-us flag-icon-squared flag-icon-item"></i></a>
                  <a class="item-flag clickable" (click)="selectLanguage('pt-BR')"><i class="flag-icon flag-icon-br flag-icon-squared flag-icon-item"></i></a>
                  <a class="item-flag clickable" (click)="selectLanguage('es')"><i class="flag-icon flag-icon-es flag-icon-squared flag-icon-item"></i></a>
                  <a class="item-flag clickable" (click)="selectLanguage('ru')"><i class="flag-icon flag-icon-ru flag-icon-squared flag-icon-item"></i></a>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
        <div class="col-auto p-0 px-1">
          <div class="notification-bell-area">
            <i (click)="notificationBellOpen = !notificationBellOpen" class="fa" [class]="notificationBellContent.count > 0 ? 'fa-bell' : 'fa-bell-o'"></i>
            <span (click)="notificationBellOpen = !notificationBellOpen" class="bell-number" *ngIf="notificationBellContent.count > 0">{{ notificationBellContent.count < 10 ? notificationBellContent.count : '+9' }}</span>
            <div *ngIf="notificationBellOpen" class="notification-bell-content card">
              <div class="card-body p-0">
                <div class="notification-bell-item" *ngFor="let item of notificationBellContent.items">
                  <a href="/approve-tournaments"><i class="fa fa-inbox mr-2"></i>{{ item }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-auto p-0 px-1">
          <li class="nav-item dropdown" dropdown placement="bottom right" style="list-style: none;">
            <a class="nav-link px-1" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false" dropdownToggle (click)="false">
              <img src="assets/img/avatars/profile.png" class="img-avatar" alt="user profile" />
              <img width="0" height="0" src="assets/img/brand/logo_rectangulo.png" />
            </a>
            <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
              <a class="dropdown-item" href="#">
                <i class="fa fa-user"></i>
                <span class="email-badge">{{ userEmail }}</span>
                <span>{{ userName }}</span>
              </a>
              <ng-container *ngIf="tierLicense$ | withLoading | async as wait">
                <div class="dropdown-item" *ngIf="wait.value as tierLicense">
                  Available slots {{ tierLicense.used_slots }}/{{ tierLicense.available_slots }} [{{licenseType}}]
                </div>
                <div class="dropdown-item disabled" *ngIf="wait.loading"><i class="fa fa-arrow-circle-o-down"></i>{{ 'loading' | translate }}...</div>
              </ng-container>
              <ng-container *ngIf="mySubText$ | withLoading | async as wait">
                <div class="dropdown-item" *ngIf="wait.value as value">
                  Subscription: {{ value }}
                </div>
                <div class="dropdown-item disabled" *ngIf="wait.loading"><i class="fa fa-arrow-circle-o-down"></i>{{ 'loading' | translate }}...</div>
              </ng-container>
              <div class="dropdown-header text-center"><strong>Settings</strong></div>
              <a class="dropdown-item" href="https://auth.lobbyze.com/recover"><i class="fa fa-user"></i>{{ 'manage_password' | translate }}</a>
              <a class="dropdown-item" (click)="openSubscription()" style="cursor: pointer"><i class="fa fa-usd"></i>{{ 'subscription' | translate }}</a>
              <a class="dropdown-item" style="cursor: pointer" (click)="$event.stopPropagation(); toggleUITheme()" ><i class="fa" [ngClass]="{'fa-moon-o': !isDarkTheme, 'fa-sun-o': isDarkTheme}"></i>{{ isDarkTheme ? ('light_theme' | translate) : ('dark_theme' | translate)}}</a>
              <div class="divider"></div>
              <div class="dropdown-header text-center"><strong>{{ 'session' | translate }}</strong></div>
              <a class="dropdown-item" style="cursor: pointer" (click)="logout()"><i class="fa fa-lock"></i> {{ 'logout' | translate }}</a>
            </div>
          </li>
        </div>
      </div>
    </div>
    <div class="col-12 d-block d-md-none p-0 px-1">
      <div class="dropdown tier-dropdown mr-2" dropdown placement="bottom">
        <div class="tier-name" dropdownToggle>
          <span class="pl-1">{{ team }}</span>
          <i class="fa fa-angle-down" tooltip="Open tier list" triggers="hover" placement="bottom"></i>
        </div>
        <div class="dropdown-menu dropdown-menu-right tier-list" *dropdownMenu>
          <div class="row m-0" style="min-width: 350px;">
            <div class="col-6 p-0">
              <ng-container *ngIf="allowNewTier$ | withLoading | async as allowNewTier">
                <a
                  *ngIf="allowNewTier.value; else notAllow"
                  class="dropdown-item clickable"
                  (click)="addNewTierModal.show()"
                >
                  Add new tier <i class="ml-1 fa fa-plus"></i>
                </a>
                <ng-template #notAllow>
                  <a class="dropdown-item"
                    tooltip="{{ 'tiers_must_have_4_users' | translate }}"
                    triggers="hover"
                    placement="bottom"
                  >
                    Add new tier<i class="ml-1 fa fa-ban"></i>
                  </a>
                </ng-template>
              </ng-container>
            </div>
            <div class="col-6 p-0">
              <div class="input-group mt-1">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="fa fa-search"></i></span>
                </div>
                <input class="form-control" [(ngModel)]="tierSearch" (click)="$event.stopPropagation()" type="text">
              </div>
            </div>
          </div>
          <ng-container *ngIf="tierList$ | withLoading | async as wait">
            <ng-container *ngIf="wait.value as tierList">
              <ng-container *ngFor="let tier of tierList">
                <a class="dropdown-item clickable" [class]="tier.member_count != null ? 'owner' : ''" *ngIf="searchCheck(tier.name)" (click)="selectTier(tier)"><i class="fa fa-book"></i>{{ tier.name }}{{ tier.member_count != null ? ' - ' + tier.member_count + ' users' : '' }}</a>
              </ng-container>
            </ng-container>
            <span class="dropdown-item disabled" *ngIf="wait.loading"><i class="fa fa-arrow-circle-o-down"></i>{{ 'loading' | translate }}...</span>
            <span class="dropdown-item disabled" *ngIf="wait.error"><i class="fa fa-exclamation"></i>Error</span>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</app-header>
</ng-container>
<div class="app-body">

  <ng-container *ngIf="!isShopPage()">
    <app-sidebar #appSidebar [fixed]="true" [display]="'lg'" [minimized]="sidebarMinimized" (minimizedChange)="toggleMinimize($event)">
      <app-sidebar-minimizer class="sidebar-toggle-button"></app-sidebar-minimizer>
      <app-sidebar-nav [navItems]="navItems" [perfectScrollbar]></app-sidebar-nav>
    </app-sidebar>
  </ng-container>
  
  <!-- Main content -->
  <main *ngIf="userData != null" class="main" [ngStyle]="{'margin-right': mainMarginRight}">
    <div *ngIf="!isUserVerified && licenseType == 'free'" class="warning-verified"><i class="fa fa-warning mr-1"></i>{{ licenseType == 'free' ? ('verify_account_free' | translate) : ('verify_account' | translate) }}. <a (click)="sendValidation()" class="send-verification-link">{{ 'send_another_email' | translate }}</a></div>
    <!-- Breadcrumb -->
    <!-- breaking change 'cui-breadcrumb' -->
    <!-- <cui-breadcrumb> -->
      <!-- Breadcrumb Menu-->
      <!-- <li class="breadcrumb-menu d-md-down-none">
        <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
          <a class="btn" href="#"><i class="icon-speech"></i></a>
          <a class="btn" [routerLink]="['/dashboard']"><i class="icon-graph"></i> &nbsp;Dashboard</a>
          <a class="btn" href="#"><i class="icon-settings"></i> &nbsp;Settings</a>
        </div>
      </li> -->
    <!-- </cui-breadcrumb> -->

    <!-- deprecation warning for 'app-breadcrumb' -->
    <!--<ol class="breadcrumb">-->
    <!--<app-breadcrumb></app-breadcrumb>-->
    <!--&lt;!&ndash; Breadcrumb Menu&ndash;&gt;-->
    <!--<li class="breadcrumb-menu d-md-down-none">-->
    <!--<div class="btn-group" role="group" aria-label="Button group with nested dropdown">-->
    <!--<a class="btn" href="#"><i class="icon-speech"></i></a>-->
    <!--<a class="btn" [routerLink]="['/dashboard']"><i class="icon-graph"></i> &nbsp;Dashboard</a>-->
    <!--<a class="btn" href="#"><i class="icon-settings"></i> &nbsp;Settings</a>-->
    <!--</div>-->
    <!--</li>-->
    <!--</ol>-->
    <div class="container-fluid h-100" [ngStyle]="{'margin-top': isShopPage() ? 0 : '1.5rem'}">
      <router-outlet></router-outlet>
    </div><!-- /.container-fluid -->
  </main>

<!--   <app-aside [fixed]="true" [display]="true" [ngClass]="'test'" [ngStyle]="{'margin-right': sideMarginRight}">
    <tabset>
      <tab (selectTab)="viewNotifications()">
        <ng-template tabHeading>
          <i class="icon-speech"></i>
          <span *ngIf="newNotifications.length > 0" class="badge badge-pill badge-danger position-absolute ml-n2 mt-n2">{{
            newNotifications.length }}</span>
        </ng-template>
        <div (click)="confirmDelete = undefined">
          <div *ngFor="let notification of notifications; let i = index; let last = last" class="message p-3">
            <div class="text-truncate font-weight-bold" [title]="notification.title">{{ notification.title }}</div>
            <div class="mt-n1"><small class="text-muted" [title]="formatDateFull(notification.date)">{{
                formatDate(notification.date) }}</small></div>
            <small class="text-muted">{{ notification.body }}</small>
            <div *ngIf="notification.actions && notification.actions.length > 0" class="mt-1">
              <button *ngFor="let action of notification.actions" class="btn btn-light border btn-sm mr-2" (click)="handleAction(notification, action.action)">{{ action.title }}</button>
            </div>
            <hr *ngIf="!last">
          </div>
          <div class="bg-secondary text-center px-4 py-2" *ngIf="notifications.length == 0">
            {{ 'no_notifications-atm' | translate }}
          </div>
        </div>
      </tab>
    </tabset>
  </app-aside> -->
  <!-- Snackbar -->
  <div id="snackbar" [ngClass]="{'show' : showSnackbar}">{{ snackbarMessage }}</div>
</div>
<ng-container *ngIf="!isShopPage()">
<app-footer>
  <span><a href="https://lobbyze.com">lobbyze</a> &copy; {{ currentYear }}</span>
  <div class="flex-grow-1">&nbsp;</div>
</app-footer>
</ng-container>
<!-- <Modal Add new tier> -->
  <div bsModal
  #addNewTierModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="Add new tier modal"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Create new tier</h4>
        <!-- TODO Translate this -->
        <button type="button" class="close" (click)="addNewTierModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <!-- Name-->
        <div class="form-group row">
          <div class="col-md-12">
            <div class="input-group" style="margin-top: 20px">
              <input autocomplete="false" type="text" class="form-control" placeholder="Schedule Name"
                [(ngModel)]="newTierName" />
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="addNewTierModal.hide()">
          {{ "cancel" | translate }}
        </button>
        <button type="button" class="btn btn-primary" [class.disabled]="isCreateNewTierInvalid"
          [disabled]="isCreateNewTierInvalid" (click)="addNewTierModal.hide(); createNewTier()">
          {{ "submit" | translate }}
        </button>
      </div>
    </div>
    <!-- /.modal-content -->
  </div>
  <!-- /.modal-dialog -->
</div>
<!-- </Modal> -->