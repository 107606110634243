import { Injectable, NgModule } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterModule,
  RouterStateSnapshot,
  Routes,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { WithLoadingModule } from "../pipe/withLoading/withLoading.module";
// Import Containers
import { DefaultLayoutComponent } from "./containers";
import { User } from "./interfaces/user";
import { UserService } from "./services/user.service";
import { P404Component } from "./views/error/404.component";
import { P500Component } from "./views/error/500.component";
import { InvalidTierLicense } from "./views/error/invalid-tier-license.component";

type ActivationFunc = (
  u: User,
  route: ActivatedRouteSnapshot
) => boolean | UrlTree;

const Guard = (callable: ActivationFunc) => {
  class RoleGuard implements CanActivate {
    constructor(private userService: UserService) {}

    activation(u: User, route: ActivatedRouteSnapshot): boolean | UrlTree {
      return callable(u, route);
    }

    canActivate(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot
    ):
      | boolean
      | UrlTree
      | Observable<boolean | UrlTree>
      | Promise<boolean | UrlTree> {
      return this.userService.getLastUser().then(user => {
        return this.activation(user, route);
      });
    }
  }

  return RoleGuard;
};

const OrFunc = (...funcs: ActivationFunc[]) => {
  return (u: User, route: ActivatedRouteSnapshot) => {
    for (const f of funcs) {
      if (f(u, route)) {
        return true;
      }
    }
    return false;
  };
};

const NotFunc = (f: ActivationFunc) => {
  return (u: User, route: ActivatedRouteSnapshot) => !f(u, route);
};

const SACond = (u: User) => u.role.toUpperCase().includes("SA");
const TACond = (u: User) => u.role.toUpperCase().includes("TA");
const MasterCond = (u: User) =>
  !u.team || u.role.toUpperCase().includes("MASTER");
const OwnerCond = (u: User) => u.role.toUpperCase().includes("OWNER");

@Injectable({ providedIn: "root" })
class SAGuard extends Guard(SACond) {
  constructor(userService: UserService) {
    super(userService);
  }
}

@Injectable()
class MasterGuard extends Guard(MasterCond) {
  constructor(userService: UserService) {
    super(userService);
  }
}

@Injectable()
class NotMasterGuard extends Guard(NotFunc(MasterCond)) {
  constructor(userService: UserService) {
    super(userService);
  }
}

@Injectable()
class SaOrTaGuard extends Guard(OrFunc(SACond, TACond)) {
  constructor(userService: UserService) {
    super(userService);
  }
}

@Injectable()
class DefaultPageGuard extends Guard(null) {
  constructor(private router: Router, userService: UserService) {
    super(userService);
  }

  activation(u: User, route: ActivatedRouteSnapshot): boolean | UrlTree {
    let path: string;
    if (MasterCond(u)) {
      path = "/tournaments";
    } else {
      path = "/scheduled-tournaments";
    }
    return this.router.createUrlTree([path], {
      queryParams: route.queryParams,
    });
  }
}

@Injectable()
class OwnerGuard extends Guard(OwnerCond) {
  constructor(userService: UserService) {
    super(userService);
  }
}

export const routes: Routes = [
  {
    path: "",
    children: [],
    pathMatch: "full",
    canActivate: [DefaultPageGuard],
  },
  {
    path: "_",
    children: [],
    pathMatch: "full",
    canActivate: [DefaultPageGuard],
  },
  {
    path: "404",
    component: P404Component,
    data: {
      title: "Page 404",
    },
  },
  {
    path: "500",
    component: P500Component,
    data: {
      title: "Page 500",
    },
  },
  {
    path: "",
    component: DefaultLayoutComponent,
    data: {
      title: "Home",
    },
    children: [
      {
        path: "home-page",
        loadChildren: () =>
          import("./views/home-page/home-page.module").then(
            (m) => m.HomePageModule
          )
      },
      {
        path: "tournaments",
        loadChildren: () =>
          import("./views/tournaments/tournaments.module").then(
            (m) => m.TournamentsModule
          ),
        canActivate: [MasterGuard],
      },
      {
        path: "approve-tournaments",
        loadChildren: () =>
          import("./views/approve-tournaments/approve-tournaments.module").then(
            (m) => m.ApproveTournamentsModule
          ),
        canActivate: [MasterGuard],
      },
      {
        path: "requested-tournaments",
        loadChildren: () =>
          import(
            "./views/requested-tournaments/requested-tournaments.module"
          ).then((m) => m.RequestedTournamentsModule),
      },
      /* {
        path: "comments",
        loadChildren: () =>
          import("./views/comment/comment.module").then((m) => m.CommentModule),
      }, */
      {
        path: "add-tournaments",
        loadChildren: () =>
          import("./views/add-tournament/add-tournament.module").then(
            (m) => m.AddTournamentModule
          ),
        canActivate: [SaOrTaGuard],
      },
      {
        path: "manage-sites",
        loadChildren: () =>
          import("./views/manage-sites/manage-sites.module").then(
            (m) => m.ManageFiltersModule
          ),
        canActivate: [SaOrTaGuard],
      },
      {
        path: "my-team",
        loadChildren: () =>
          import("./views/my-team/my-team.module").then(
            (m) => m.MyTeamModule
          ),
        canActivate: [MasterGuard],
      },
      {
        path: "add-tournaments-tier",
        loadChildren: () =>
          import("./views/add-tournament-tier/add-tournament-tier.module").then(
            (m) => m.AddTournamentTierModule
          ),
          canActivate: [MasterGuard],
      },
      {
        path: "manage-logs",
        loadChildren: () =>
          import("./views/manage-logs/manage-logs.module").then(
            (m) => m.ManageLogsModule
          ),
        canActivate: [MasterGuard],
      },
      {
        path: "manage-filters",
        loadChildren: () =>
          import("./views/manage-filters/manage-filters.module").then(
            (m) => m.ManageFiltersModule
          ),
        canActivate: [MasterGuard],
      },
      {
        path: "level-config",
        loadChildren: () =>
          import("./views/tournament-level/tournament-level.module").then(
            (m) => m.TournamentLevelModule
          ),
        canActivate: [MasterGuard],
      },
      {
        path: "user-profile",
        loadChildren: () =>
          import("./views/user-profile/user-profile.module").then(
            (m) => m.UserProfileModule
          )
      },
      {
        path: "buy",
        loadChildren: () =>
          import("./views/shop/shop.module").then(
            (m) => m.ShopModule
          )
      },
      {
        path: "buy/:plan",
        loadChildren: () =>
          import("./views/shop/shop.module").then(
            (m) => m.ShopModule
          )
      },
      {
        path: "manage-users",
        loadChildren: () =>
          import("./views/manage-users/manage-users.module").then(
            (m) => m.ManageUsersModule
          ),
        canActivate: [SAGuard],
      },
      {
        path: "manage-users/:id",
        loadChildren: () =>
          import("./views/manage-users/manage-users.module").then(
            (m) => m.ManageUsersModule
          ),
        canActivate: [SAGuard],
      },
      {
        path: "manage-teams",
        loadChildren: () =>
          import("./views/manage-teams/manage-teams.module").then(
            (m) => m.ManageTeamsModule
          ),
        canActivate: [SAGuard],
      },
      {
        path: "manage-teams/:id",
        loadChildren: () =>
          import("./views/manage-teams/manage-teams.module").then(
            (m) => m.ManageTeamsModule
          ),
        canActivate: [SAGuard],
      },
      {
        path: "manage-structures",
        loadChildren: () =>
          import("./views/manage-structures/manage-structures.module").then(
            (m) => m.ManageStructuresModule
          ),
        canActivate: [SaOrTaGuard],
      },
      {
        path: "manage-ads",
        loadChildren: () =>
          import("./views/manage-ads/manage-ads.module").then(
            (m) => m.ManageAdsModule
          ),
        canActivate: [SaOrTaGuard],
      },
      {
        path: "delete-data",
        loadChildren: () =>
          import("./views/delete-data/delete-data.module").then(
            (m) => m.DeleteDataModule
          ),
        canActivate: [SAGuard],
      },
      {
        path: "scheduled-tournaments",
        loadChildren: () =>
          import(
            "./views/scheduled-tournaments/scheduled-tournaments.module"
          ).then((m) => m.ScheduledTournamentsModule),
      },
      {
        path: "subscriptions",
        loadChildren: () =>
          import("./views/subscriptions/subscriptions.module").then(
            (m) => m.SubscriptionsModule
          ),
      },
      {
        path: "base",
        loadChildren: () =>
          import("./views/base/base.module").then((m) => m.BaseModule),
      },
      {
        path: "buttons",
        loadChildren: () =>
          import("./views/buttons/buttons.module").then((m) => m.ButtonsModule),
      },
      {
        path: "charts",
        loadChildren: () =>
          import("./views/chartjs/chartjs.module").then((m) => m.ChartJSModule),
      },
      {
        path: "dashboard",
        loadChildren: () =>
          import("./views/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          ),
        canActivate: [DefaultPageGuard],
      },
      {
        path: "icons",
        loadChildren: () =>
          import("./views/icons/icons.module").then((m) => m.IconsModule),
      },
      {
        path: "theme",
        loadChildren: () =>
          import("./views/theme/theme.module").then((m) => m.ThemeModule),
      },
      {
        path: "widgets",
        loadChildren: () =>
          import("./views/widgets/widgets.module").then((m) => m.WidgetsModule),
      },
      {
        path: "day",
        loadChildren: () =>
          import("./views/day/day.module").then((m) => m.DayModule),
      },
      {
        path: "stats",
        loadChildren: () =>
          import("./views/stats/stats.module").then((m) => m.StatsModule),
      },
      {
        path: 'notifications',
        loadChildren: () =>
          import("./views/notification-diagnostic/notification-diagnostic.module").then((m) => m.NotificationDiagnosticModule),
      },
      {
        path: "rebalance",
        loadChildren: () =>
          import("./views/rebalance-tournaments/rebalance-tournaments.module").then(
            (m) => m.RebalanceTournamentsModule
          ),
        canActivate: [MasterGuard],
      },
      {
        path: 'no-license',
        component: InvalidTierLicense,
      },
      {
        path: "bankroll",
        loadChildren: () =>
          import("./views/bankroll/bankroll.module").then((m) => m.BankrollModule),
      },
    ],
  },
  {
    path: "on-time-tournaments",
    loadChildren: () =>
      import("./views/on-time-tournaments/on-time-tournaments.module").then(
        (m) => m.OnTimeTournamentsModule
      ),
  },
  {
    path: "embedded",
    loadChildren: () =>
      import("./views/embedded-tournaments/embedded-tournaments.module").then(
        (m) => m.EmbeddedTournamentsModule
      ),
  },
  {
    path: "embedded/:tier",
    loadChildren: () =>
      import("./views/embedded-tournaments/embedded-tournaments.module").then(
        (m) => m.EmbeddedTournamentsModule
      ),
  },
  { path: "**", component: P404Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  declarations: [],
  providers: [
    DefaultPageGuard,
    SAGuard,
    MasterGuard,
    NotMasterGuard,
    SaOrTaGuard,
    OwnerGuard
  ],
})
export class AppRoutingModule {}
