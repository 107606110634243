import { Component, OnInit } from "@angular/core";
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { map, mergeMap, shareReplay } from "rxjs/operators";
// import { Notification } from "../../interfaces/notification";
import { Router } from '@angular/router';
import { map as _map } from "lodash";
import { navItems } from "../../_nav";
import { AdsService } from '../../services/ads.service';
import { AuthService } from "../../services/auth.service";
import { LicenseService } from "../../services/license.service";
import { MessagingService } from "../../services/messaging.service";
import { ScheduleService } from "../../services/schedule.service";
import { TierService } from "../../services/tier.service";
import { UserService } from "../../services/user.service";

// Moment
import { ShepherdService } from "angular-shepherd";
import * as moment from "moment-timezone";
import { NgxSpinnerService } from "ngx-spinner";
import { combineLatest, from, Observable, of } from "rxjs";
import { take } from "rxjs/operators";
import { environment } from "../../../environments";
import { User } from "../../interfaces/user";
import { Tier, TierLicense } from "../../interfaces/v2/tier";
import { NotificationService, RegisterStatus } from "../../services/notification.service";
import Cookies from "../../utils/cookies";
import { tour, tourRebalance } from "./tour-guide";
moment().tz("America/Los_Angeles").format();

@Component({
  selector: "app-dashboard",
  templateUrl: "./default-layout.component.html",
  styleUrls: ["./default-layout.component.scss"],
  providers: [ScheduleService, MessagingService, UserService, AuthService, LicenseService, TranslateService, ShepherdService],
})
export class DefaultLayoutComponent implements OnInit {

  currentYear: number = new Date().getFullYear()

  public numNotifications = 0;

  public sidebarMinimized = true;
  public navItems = [];
  public mainMarginRight = "0";
  public sideMarginRight = "-250px";
  public schedulesFN = [];

  public tourHover = false;
  public tourOptionsOpen = false;

  public notificationBellContent = {
    count: 0,
    items: []
  };

  public notificationBellOpen = false;

  public userName: string;
  public userEmail: string;

  public licenseType: string;
  public slots: number;
  public selfLicense: boolean;
  public isFreeLocked = false;
  public isFullLocked = false;

  public tierLicense: any = null;

  public isUserVerified: boolean = false;

  // Snackbar Message
  public showSnackbar = false;
  public snackbarMessage = "";

  public notifications: Notification[] = [];
  public confirmDelete: number;

  public userData: User = null;
  public isMaster: boolean = false;

  public isDarkTheme = Cookies.get('theme-ui') ? JSON.parse(Cookies.get('theme-ui')) : true;

  public roles: Set<string> = new Set();
  public team: string

  public allowNewTier$: Observable<boolean>;
  public tierList$: Observable<Tier[]>;
  public tierLicense$: Observable<TierLicense>;

  public mySubText$: Observable<string>;

  notificationStatus$: Observable<RegisterStatus>;

  newTierName: string = "";

  public tierSearch: string = "";

  public userCount:number = 0;

  /* public get newNotifications(): Notification[] {
    return this.notifications.filter((notification) => !notification.viewed);
  } */

  private readonly emptyAd = {
    id: null,
    title: "",
    image: "",
    link: "",
    position: "",
    start: "",
    finish: "",
    state: "disabled"
  };

  constructor(
    public auth: AuthService,
    private userService: UserService,
    private notificationService: NotificationService,
    private adsService: AdsService,
    private licenseService: LicenseService,
    private tierService: TierService,
    private translateService: TranslateService,
    private spinner: NgxSpinnerService,
    private guideTour: ShepherdService,
    private router: Router
  ) {
    this.notificationStatus$ = this.notificationService.getRegistrationStatus();
  }

  public selectLanguage(lang: string) {
    this.translateService.use(lang);
    Cookies.set('lang', lang, { expires: 365 });
  }

  public getCurrentLang() {
    return this.translateService.currentLang
  }

  toggleAside() {
    if (this.mainMarginRight == "250px") {
      this.mainMarginRight = "0";
      this.sideMarginRight = "-250px";
    } else {
      this.mainMarginRight = "250px";
      this.sideMarginRight = "0";
    }
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  logout() {
    this.auth.logout();
  }

  showSnackMessage(msg: string) {
    this.snackbarMessage = msg;
    this.showSnackbar = true;
    setTimeout(() => {
      this.showSnackbar = false;
    }, 5000);
  }

  utcToLocal(date: string) {
    const dt = moment(date).format();
    return dt;
  }

  getHasPendingRequests() {
    this.userService.getPendingRequest().pipe(take(1)).subscribe((has_pending_requests) => {
      if (this.isMaster && has_pending_requests) {
        this.notificationBellContent.count = 1;
        this.notificationBellContent.items = [`${this.translateService.instant('has_pending_requests')}`]
        setTimeout(() => this.getHasPendingRequests(), 10000)
      } else {
        this.notificationBellContent.count = 0;
        this.notificationBellContent.items = []
      }
    });
  }

  async getCurrentUser() {
    const user = this.auth.user
    this.userName = user.name
    this.userEmail = user.email
    this.isUserVerified = user.is_verified
    const data = await this.userService.getCurrentUser().toPromise()
    if (this.licenseType == "") {
      if (this.selfLicense) {
        this.goToShopUrl()
      } else {
        this.goToTierLicenseErr()
      }
    }
    const roles = new Set((data.role || '').split(',').map(e => e.trim().toUpperCase()))
    this.roles = roles
    this.team = data.team
    this.userData = data
    this.isMaster = roles.has('MASTER')
    if (this.isMaster) {
      this.getHasPendingRequests()
    }
  }

  async organizeMenuItems() {
    const userData: any = this.userData;
    userData.slots = this.slots;

    this.navItems = navItems
    .filter(e => !e.role || e.role.some(r => this.roles.has(r)))
    .filter(e => !e.filter || e.filter(userData))
    this.navItems.forEach(item => {
      if (!this.roles.has('TA') && !this.roles.has('SA') && !item.isFree && this.isFreeLocked) {
        item.attributes = { disabled: true }
      }
      if (item.i18nkey == 'tournaments') {
        item.attributes = { class: 'tournaments-page' }
      } else if (item.i18nkey == 'manage_schedules') {
        item.attributes = { class: 'schedule-page' }
      } else if (item.i18nkey == 'download_notification_app') {
        item.attributes = { class: 'download-page' }
      } else if (item.i18nkey == 'rebalance_schedule') {
        item.attributes = { class: 'rebalance-page' }
      }
      item.name = this.translateService.instant(item.i18nkey)
    });
  }

  async goToShopUrl() {
    if (!this.router.url.startsWith('/buy')) {
      this.router.navigate(['/buy'])
    }
  }

  async goToTierLicenseErr() {
    if (!this.router.url.startsWith('/no-license')) {
      this.router.navigate(['/no-license'])
    }
  }

  async getUserLicense() {
    const license = await this.licenseService.getLicense();
    this.licenseType = license.license;
    this.slots = license.slots;
    this.selfLicense = license.self;
    this.isFreeLocked = await this.licenseService.isFreeLocked();
    this.isFullLocked = await this.licenseService.isFullLocked();
  }

  async sendValidation() {
    const resp = await this.userService
      .sendValidation()
      .toPromise();
    if (resp.ok) {
      return;
    }
  }

  /*   async getNotifications() {
      this.notifications = await this.notificationService.get().toPromise().then((data) => data.filter((notification) => {
        const now = moment()
        const then = moment(notification.date)
        return then.isSameOrAfter(now, "minute")
      }))
    } */

  formatDate(d: string) {
    const date = new Date(d)
    return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`
  }

  formatDateFull(date: Date) {
    return new Date(date).toLocaleString();
  }

  /*   async viewNotifications() {
      await this.notificationService
        .view(this.notifications.map((notification) => notification.id))
        .toPromise();
      this.getNotifications();
    }

    async deleteNotification(id: number) {
      if (this.confirmDelete == id) {
        await this.notificationService.dismiss([id]).toPromise();
        this.confirmDelete = undefined;
        this.getNotifications();
      } else {
        this.confirmDelete = id;
      }
    }

    async handleAction(notification: Notification, action: string) {
      await this.notificationService.handleAction(notification, action);
      this.getNotifications()
    } */

  downloadLog() {
    window.location.href = environment.API_URL + "v2/log/download"
  }

  downloadNotificationApp() {

  }

  WindowResize(e) {
    setTimeout(() => { window.dispatchEvent(new Event('resize')); }, 250);
  }

  async ngOnInit() {

    if(Cookies.get("ui-version") && Cookies.get("ui-version") == "classic") {
      location.href = "https://classic.lobbyze.com/";
    }

    if (this.isDarkTheme) {
      this.toggleUITheme();
    }

    if (!this.auth.isLoggedIn()) {
      this.auth.login();
      return
    }

    const user = this.auth.user
    if (user) {
      await this.getUserLicense();
      await this.getCurrentUser();
      // Notification.requestPermission();
      if(this.isMaster && this.licenseType != 'free' && (!Cookies.get('showed-guide') || (Cookies.get('showed-guide') && Cookies.get('showed-guide') != 'true')) && !this.isShopPage()) {
        this.executeNonMasterGuide();
      }
    }

    this.tierLicense$ = (
      this.isMaster ? this.tierService.getTierLicense() : of(null)
    ).pipe(
      map((data) => {
        if(!data) {
          return null
        }
        if(data.available_slots == -1) {
          return null
        }
        return data
      }),
      shareReplay(),
    );

    this.allowNewTier$ = this.tierLicense$.pipe(
      map(license => {
        return (
          license && 
          license.available_slots > license.used_slots &&
          license.tiers.every((e) => e.blocking)
        );
      })
    );

    this.tierList$ = combineLatest([
      this.userService.listTiers(),
      this.tierLicense$,
    ]).pipe(
      map(([data, license]) => {
        data.sort((a, b) => {
          return a.name.toLowerCase() > b.name.toLowerCase()
            ? 1
            : a.name.toLowerCase() === b.name.toLowerCase()
              ? 0
              : -1;
        });
        const licenseMap = new Map((license && license.tiers || []).map((e) => [e.id, e]));
        return data.map((e) => {
          const l = licenseMap.get(e.id);
          return {
            ...e,
            member_count: l ? l.member_count : null,
          };
        })
      })
    );

    this.mySubText$ = of(null).pipe(
      mergeMap(() => {
        return from(this.licenseService.currentSubscription())
      }),
      map((data) => {
        if (!data.has) {
          return "No license";
        }
        let t = data.license_type
        if (data.quantity > 1) {
          t += ` [${data.quantity}]`
        }
        return t
      })
    )

    // this.getAds();
    /* this.getNotifications();
    setInterval(() => this.getNotifications(), 60e3); */

    this.organizeMenuItems();

    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.navItems.forEach(item => {
        item.name = this.translateService.instant(item.i18nkey)
      });
      this.navItems = JSON.parse(JSON.stringify(this.navItems))
    });
  }

  selectTier(tier: Tier) {
    const tierId = tier.id.toString()
    Cookies.set('tier', tierId)
    window.location.reload();
  }

  get isCreateNewTierInvalid(): boolean {
    return this.newTierName == null || this.newTierName.length <= 0;
  }

  async createNewTier() {
    if (this.newTierName == null || this.newTierName.length <= 0) {
      return
    }
    try {
      this.spinner.show()

      const name = this.newTierName;

      const newTier: any = await this.tierService.createTier(name).toPromise();

      this.selectTier(newTier.tier);

    } finally {
      this.spinner.hide()
    }
  }

  executeMasterGuide(): void {
    this.guideTour.defaultStepOptions = tour.defaultStepOptions;
    this.guideTour.modal = true;
    this.guideTour.confirmCancel = false;
    this.guideTour.addSteps(tour.steps);
    this.guideTour.start();
  }

  executeNonMasterGuide(): void {
    var tourAux = { ...tour };
    if (this.router.url == '/tournaments') {
      tourAux.steps.forEach((element, index) => {
        if (element.id == 'tournaments-page') tourAux.steps.splice(index, 1);
      });
    }

    tourAux.steps.map((e) => {
      e.text = this.translateService.instant(e.i18n_text)
      e.title = this.translateService.instant(e.i18n_title)
      _map(e.buttons, (b) => {
        if(b['i18n']) {
          b['text'] = this.translateService.instant(b['i18n'])
        }
      })
    })

    this.guideTour.defaultStepOptions = tourAux.defaultStepOptions;
    this.guideTour.modal = true;
    this.guideTour.confirmCancel = false;
    this.guideTour.addSteps(tourAux.steps);
    this.guideTour.start();
  }

  executeRebalanceGuide(): void {
    var tourAux = { ...tourRebalance };
    if (this.router.url == '/rebalance') {
      tourAux.steps.forEach((element, index) => {
        if (element.id == 'rebalance-page') tourAux.steps.splice(index, 1);
      });
    }

    tourAux.steps.map((e) => {
      e.text = this.translateService.instant(e.i18n_text)
      e.title = this.translateService.instant(e.i18n_title)
      _map(e.buttons, (b) => {
        if(b['i18n']) {
          b['text'] = this.translateService.instant(b['i18n'])
        }
      })
    })

    this.guideTour.defaultStepOptions = tourAux.defaultStepOptions;
    this.guideTour.modal = true;
    this.guideTour.confirmCancel = false;
    this.guideTour.addSteps(tourAux.steps);
    this.guideTour.start();
  }

  searchCheck(name: string): boolean {
    return (name.toUpperCase().startsWith(this.tierSearch.toUpperCase()))
  }

  toggleUITheme() {
    this.isDarkTheme = document.body.classList.toggle('dark-theme');
    Cookies.set('theme-ui', this.isDarkTheme.toString(), { expires: 365 });
  }

  getClassicModeUrl() {
    return window.location.hostname.includes("classic") ? {checked: true, url: "https://app.lobbyze.com"} : {checked: false, url: "https://classic.lobbyze.com/"};
  }

  goToClassicView(){
    Cookies.set('ui-version', "classic", { expires: 365, domain: "lobbyze.com" });
    location.href = "https://classic.lobbyze.com/";
  }

  isShopPage() {
    const path = window.location.pathname;
    return path == '/buy' || path == '/buy/small' || path == '/buy/full';
  }

  async openSubscription() {
    this.spinner.show()
    try {
      const license = await this.licenseService.currentSubscription()
      if (license.has) {
        const form = document.createElement('form')
        form.method = 'POST'
        form.action = this.licenseService.subscriptionPortalUrl()
        const returnURLField = form.appendChild(document.createElement('input'))
        returnURLField.type = 'hidden'
        returnURLField.name = 'return_url'
        returnURLField.value = location.href
        document.body.appendChild(form)
        form.submit()
      } else {
        this.router.navigate(['/buy'])
        this.spinner.hide()
      }
    } catch(e) {
      this.spinner.hide()
    }
  }

}
